

.full-height-container2 {
    min-height: 95.6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202020; /* Ustaw ciemne tło dla całej strony */
    padding: 20px; /* Dodaj padding do kontenera, aby uniknąć przylegania do krawędzi */
  }
  
  .full-height-container {
    min-height: 95.6vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between; /* Upewnij się, że elementy są rozmieszczone równomiernie w pionie */
    background-color: #202020;
    max-width: 9000px;
    padding: 20px;
  }
  
  .custom-container {
    width: 100%;
    max-width: 900px;
    background-color: #2c2f33;
    padding: 60px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.8);
    color: #ddd;
    margin-bottom: 20px; /* Odstęp na dole */
    margin-top: 120px; 
  }
  
  .custom-controls {
    display: flex;
    flex-direction: column;
    align-items: center; /* Wycentruj zawartość w pionie */
    margin-bottom: 20px;
  }
  
  
  .custom-file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .custom-file-input {
    display: none;
  }
  
  .custom-file-label {
    background-color: #3a3d41; /* Kolor tła etykiety */
    color: #ddd; /* Kolor tekstu etykiety */
    padding: 14px;
    border-radius: 8px;
    cursor: pointer;
    width: 95%;
    margin-top: 8px;
    margin-bottom: 20px;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  
  .custom-file-label:hover {
    background-color: #4a4d51; /* Jaśniejszy kolor tła etykiety po najechaniu */
  }
  
  .custom-file-label:active {
    background-color: #4a4d51; /* Ciemniejszy kolor tła etykiety po kliknięciu */
  }
  
  /* Dodaj style dla inputu typu plik, aby ukryć standardowy wygląd */
  .custom-file-input[type="file"] {
    display: none;
  }
  
  .custom-text-input {
    width: 95%;
    border: none;
    border-radius: 8px;
    padding: 15px;
    background-color: #3a3d41;
    color: #ddd;
    margin-top: 8px;
    margin-bottom: 20px;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  
  .custom-text-input:hover {
    background-color: #4a4d51;
    color: #ffffff;
    cursor: text; /* Dodaj wskaźnik tekstowy */
  }
  
  .custom-button {
    font-size: 1.2rem;
    padding: 14px 30px;
    border: none;
    border-radius: 8px;
    background-color: #7289da; /* Kolor tła przycisku */
    color: #ffffff; /* Kolor tekstu przycisku */
    transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5); /* Dodaj cień dla głębi */
  }
  
  .custom-button:hover {
    background-color: #8a9edf; /* Jaśniejszy kolor tła po najechaniu */
    transform: scale(1.05); /* Lekko powiększa przycisk */
    cursor: pointer; /* Dodaj wskaźnik, aby pokazać, że przycisk jest interaktywny */
  }
  
  /* Style dla paska postępu */
  .custom-progress-bar .progress-bar {
    background-color: #007bff;
    border-radius: 6px; /* Dodaj zaokrąglenie do paska postępu */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5); /* Dodaj cień dla głębi */
  }
  
  /* Style dla toastów */
  .toast-container {
    z-index: 1060; /* Upewnij się, że toasty są nad innymi elementami */
  }
  
  /* Dodaj style dla tekstów, aby były lepiej widoczne na ciemnym tle */
  .text-muted {
    color: #b0b3b8 !important; /* Zmieniona jasność tekstu dla lepszej widoczności */
  }
  
  
  .audio-controls {
    display: flex;
    margin-left: -1600px;
    margin-bottom: -80px;
    align-items: center;
  }
  
  .audio-control-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    
    background-color: #7289da;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .audio-control-button:hover {
    transform: scale(1.05);
    background-color: #5a6eae;
  }
  
  .volume-slider {
    margin-left: 20px;
    cursor: pointer;
  }
  
  
  .extra-buttons {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    width: 100%;
  }
  
  .extra-buttons .custom-button {
    margin-right: 10px;
  }
  
  
  
  
  
  
  
  
  
  .overlay {
    position: fixed; /* Pozycjonowanie względem viewportu */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Przyciemnienie tła */
    display: flex;
    justify-content: center; /* Wycentrowanie w poziomie */
    align-items: center; /* Wycentrowanie w pionie */
    z-index: 1070; /* Upewnij się, że nakładka jest nad innymi elementami */
  }
  
  .overlay-content {
    padding: 40px;
    background-color: #2c2f33;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    text-align: center;
    width: 100%; /* Określ szerokość nakładki */
    max-width: 800px; /* Maksymalna szerokość, aby zapewnić responsywność */
    font-size: 20px; /* Zwiększ rozmiar tekstu */
  }
  
  
  .overlay-content div {
    margin: 10px 0; /* Dodaj margines dla odstępu */
    display: flex; /* Ustaw elementy w linii */
    align-items: center; /* Wycentruj elementy w pionie */
    justify-content: space-between; /* Rozmieść treść i przycisk na przeciwnych końcach */
  }
  
  .overlay-content button {
    padding: 5px 15px;
    font-size: 14px;
    background-color: #7289da; /* Niebieskie tło */
    color: white; /* Biały tekst */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5); /* Dodaj cień dla głębi */
  }
  
  .overlay-content button:hover {
    background-color: #8a9edf;/* Ciemniejszy odcień niebieskiego */
    transform: scale(1.05);
  }
  
  /* Styl dla przycisku Close */
  .overlay-content > button {
    margin-top: 20px; /* Margines od góry dla przycisku zamknięcia */
    padding: 10px 20px;
    font-weight: bold; /* Pogrubienie tekstu */
  }
  
  
  
  
  
  .custom-progress-bar {
    width: 95%;
    margin-top: 20px;
    background-color: #3a3d41; /* Background color of the progress bar container */
  }
  
  .progress-bar {
    background-color: #007bff; /* Color of the progress bar */
    border-radius: 6px; /* Add border-radius to make it rounded */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
  }


  