.whois-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .result-container {
    margin-top: 20px;
  }
  
  .ip-info {
    font-weight: bold;
  }
  
  .error-message {
    color: red;
  }
  
  .whois-data {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    overflow: auto;
  }
  
  .divider {
    margin-top: 20px;
  }