/* Add these styles to your existing CSS file */
.game-options {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }
  
  .game-option-label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    margin: 0 15px;
    margin-top: 10px;
  }
  
  .game-option-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin-left: -25px; /* Adjust the margin to create space for the circle */
  }
  
  .checkmark {
    position: absolute;
    top: 200%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25px;
    width: 25px;
    background-color: #2c2f33;
    border-radius: 50%;
    border: 2px solid #7289da;
  }
  
  .game-option-label:hover input ~ .checkmark {
    background-color: #4a4d51;
  }
  
  .game-option-label input:checked ~ .checkmark {
    background-color: #7289da;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .game-option-label input:checked ~ .checkmark:after {
    display: block;
  }
  
  .game-option-label .checkmark:after {
    top: 6px;
    left: 6px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: white;
  }
  
  .custom-button:disabled {
    cursor: not-allowed;
    background-color: #5e5e5e;
    border-color: #5e5e5e;
  }
  