.full-height-container2 {
    min-height: 95.6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202020; 
    padding: 20px; 
  }


.custom-container2 {
    width: 100%;
    max-width: 600px;
    background-color: #2c2f33;
    padding: 60px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.8);
    color: #ddd;
    margin-bottom: 20px;
    margin-top: 200px; 
  }

.not-found-container {
  min-height: 95.6vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #202020;
  padding: 20px;
  }
  
  .not-found-title {
    font-size: 2em;
    font-weight: bold;
    color: white;
  }
  
  .not-found-message {
    font-size: 1.2em;
    color: white;
  }
  

  .custom-button {
    font-size: 1.2rem;
    padding: 14px 30px;
    border: none;
    border-radius: 8px;
    background-color: #7289da;
    color: #ffffff;
    transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  }
  
  .custom-button:hover {
    background-color: #8a9edf;
    transform: scale(1.05);
    cursor: pointer;
  }